import { ErrorBoundary } from '@amzn/sitc-frontend/components';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { AppProps } from 'single-spa';
import singleSpaReact, { SingleSpaReactOpts } from 'single-spa-react';

import { StudiosOriginSearchAppProps } from './components/StudiosOriginSearchApp';

const isStandalone = !!process.env.STANDALONE;

const StandaloneRoot = React.lazy(() => import('./components/Standalone'));

const EmbeddedRoot = React.lazy(() => import('./components/Embedded'));

let errorBoundary: SingleSpaReactOpts<StudiosOriginSearchAppProps & AppProps>['errorBoundary'] | undefined;
let suppressComponentDidCatchWarning = true;

if (isStandalone) {
  errorBoundary = (error: Error, errorInfo: React.ErrorInfo) => <ErrorBoundary error={error} info={errorInfo} />;
  suppressComponentDidCatchWarning = false;
}

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: isStandalone ? StandaloneRoot : EmbeddedRoot,
  errorBoundary,
  suppressComponentDidCatchWarning,
});

export const { bootstrap, mount, unmount } = lifecycles;
